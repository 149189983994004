<template>
  <v-card tile flat width="100%" class="pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="6" class="pt-0 pb-0 pl-0 pr-0 pr-md-1">
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'Main',
  mounted () {

  },
  beforeDestroy () {

  },
  computed: {
    _size () {
      return {
        height: `${this.$vuetify.breakpoint.height / 2}px`
      }
    }
  }
}
</script>

<style scoped>

</style>
